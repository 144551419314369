import React from 'react';
import Group from './Group';
import { useSelector } from 'react-redux';
import Newsletter from './Newsletter';

export default function GroupList() {

    const data = useSelector(state => state.appGroups.appGroups);

    return data.map((group) => (
        group.groupName === 'Newsletter' ? <Newsletter key={group.id}/> : <Group key={group.id} group={group} />
    ));
}