import axios from 'axios';
import UserService from './UserService';

// Add a request interceptor
axios.interceptors.request.use((config) => {
    if (UserService.isLoggedIn()) {
        const cb = () => {
            config.headers.Authorization = `Bearer ${UserService.getToken()}`;
            return Promise.resolve(config);
        };
        return UserService.updateToken(cb);
    }
    return config
});

// Add a response interceptor
/*
axios.interceptors.response.use((response) => {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});
*/

export default axios;
/*
const _axios = axios.create();
_axios.interceptors.request.use((config) => {
    if (UserService.isLoggedIn()) {
        const cb = () => {
            config.headers.Authorization = `Bearer ${UserService.getToken()}`;
            return Promise.resolve(config);
        };
        return UserService.updateToken(cb);
    }
});*/