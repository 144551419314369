import React from 'react'
import { Box, Button, Card, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { MobileDateTimePicker, LocalizationProvider, deDE } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { deleteAlert, editAlert } from '../../services/actions/alerts';
import { useDispatch } from 'react-redux';

export default function WarningEdit(props) {

  const { warning, setShow } = props;
  const dispatch = useDispatch()

  const [warningEdit, setWarningEdit] = React.useState({
    id: "",
    severity: "",
    message: "",
    start: dayjs("2000-01-01T00:00"),
    end: dayjs("2000-01-01T00:00"),
    button_text: "",
    url: "",
  });

  React.useEffect(() => {
    initialize(warning)
  }, [warning]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWarningEdit(warningEdit => ({
      ...warningEdit,
      [name]: value
    }))
  }

  const initialize = (_warning) => {
    setWarningEdit({
      ..._warning,
      url: _warning.url ? _warning.url : "",
      button_text: _warning.button_text ? _warning.button_text : "",
      start: dayjs(_warning.start),
      end: dayjs(_warning.end)
    })
  }

  const handleStartDateChange = (newValue) => {
    setWarningEdit(warningEdit => ({
      ...warningEdit,
      start: newValue
    }))
  }

  const handleEndDateChange = (newValue) => {
    setWarningEdit(warningEdit => ({
      ...warningEdit,
      end: newValue
    }))
  }

  const handleSend = (event) => {
    dispatch(editAlert(warningEdit))
    initialize(warning)
    setShow(false)
    event.preventDefault()

  }

  const handleDelete = (event) => {
    dispatch(deleteAlert(warningEdit))
    event.preventDefault()
  }

  const handleAbort = (event) => {
    initialize(warning)
    setShow(false)
  }

  return (
    <Card className="edit" >
      <Box component="form" onSubmit={handleSend} className="WarningEdit">
        <div className="row">
          <FormControl>
            <RadioGroup name="severity" value={warningEdit.severity} onChange={handleChange}>
              <FormControlLabel value="info" control={<Radio />} label="Info" className="RadioInfo" />
              <FormControlLabel value="warning" control={<Radio />} label="Warnung" className="RadioWarning" />
              <FormControlLabel value="critical" control={<Radio />} label="Kritisch" className="RadioCritical" />
            </RadioGroup>
          </FormControl>
          <div className="WarningTextFields">
            <TextField
              id="message-textfield"
              name="message"
              label="Meldungstext"
              variant="outlined"
              multiline
              minRows={4}
              maxRows={8}
              required
              value={warningEdit.message}
              onChange={handleChange}
            />
            <div className="row url">
              <TextField
                id="url-textfield"
                name="url"
                label="URL (https://beispiel.de)"
                variant="outlined"
                type="url"
                value={warningEdit.url}
                onChange={handleChange}
              />
              <TextField
                id="button-textfield"
                name="button_text"
                label="Button Text"
                variant="outlined"
                required={warningEdit.url ? true : false}
                value={warningEdit.button_text}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="WarningDateTime">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'de'} localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}>
              <MobileDateTimePicker
                label="Von"
                views={['day', 'hours']}
                format={"DD.MM.YYYY HH:mm"}
                value={warningEdit.start}
                onChange={(newValue) => handleStartDateChange(newValue)}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'de'} localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}>
              <MobileDateTimePicker
                label="Bis"
                views={['day', 'hours']}
                format={"DD.MM.YYYY HH:mm"}
                minDateTime={warningEdit.start}
                value={warningEdit.end}
                onChange={(newValue) => handleEndDateChange(newValue)}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="row">
          <div className="WarningButtons">
            <Button variant="contained" className="secondary" disableElevation onClick={handleAbort}>Abbrechen</Button>
            <Button variant="contained" className="ButtonDelete" disableElevation onClick={handleDelete}>Löschen</Button>
            <Button type="submit" variant="contained" disableElevation>Speichern</Button>
          </div>
        </div>
      </Box>
    </Card>
  )
}
