import { CREATE_SNACKBAR, DISCARD_SNACKBAR} from '../actions/types.js';

const initialState = {
    current_snackbar_state: false,
    snackbar_text:"",
    snackbar_level: "success"
}

export default function Snackbar(state = initialState, action) {
    switch(action.type) {
        case CREATE_SNACKBAR:
            return {
                ...state, 
                current_snackbar_state: true,
                snackbar_text: action.payload.snackbar_text,
                snackbar_level: action.payload.snackbar_level
            }
        
        case DISCARD_SNACKBAR:
            return {
                ...state, 
                current_snackbar_state: false
            }
            
        default:
            return state
    }

}