import React from 'react';
import AppLink from './AppLink';

export default function AppLinkList(props) {

  const { appLinks, color } = props

  if (appLinks) {
    return appLinks.map((appLink) => (
      <AppLink key={appLink.id} appLink={appLink} color={color} />
    ))
  } else {
    return (
      <div>Hi, leider konnte keine Verbindung hergestellt werden. Vielleicht bist du nicht mit dem Netzwerk verbunden, vielleicht gibt es aber auch ein anderes Problem. Bleibt das Problem bestehen, ruf uns gern an: 040 227227 79</div>
    );
  }

}