import React, { useEffect, useState } from 'react';
import { Card, Container, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollToTop from './ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { getAppLinkDetails } from '../services/actions/appLinks';
import { useParams } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';

export default function AppLinkDetails() {

    const dispatch = useDispatch()
    const [loading , setLoading] = useState(true)

    const appLinkDetails = useSelector(state => state.appLinks.appLinkDetails)

    const { id } = useParams();
  
    useEffect(() => {
      dispatch(getAppLinkDetails(setLoading, id))
  
    }, [dispatch, id]);
  
    if (loading) {
      return (
        <LoadingScreen/>
      )
    }

  return (
    <Container className={"main details " + (appLinkDetails.appGroup === 1 ? "grasgruen" : appLinkDetails.appGroup === 2 ? "hellblau" : appLinkDetails.appGroup === 3 ? "aubergine" : appLinkDetails.appGroup === 4 ? "orange" : "")} maxWidth="lg">
      <ScrollToTop />
      <Card className="DetailsHeader">
        <div className="icon">
          <FontAwesomeIcon icon={appLinkDetails.icon} />
        </div>
        <Typography variant="h2">{appLinkDetails.friendlyName}</Typography>
      </Card>
      <div className="grid">
        <Card>
          <Typography variant="h3">{appLinkDetails.name}</Typography>
          <Typography>{appLinkDetails.description}</Typography>
          <Typography variant="h3">Zielgruppe</Typography>
          <Typography>{appLinkDetails.targetAudience}</Typography>
        </Card>
        <div className="DetailsSidebar">
          <Card className="app-list border-top">
            <ul className="list-unstyled">
              <li>
                <a href={appLinkDetails.link} className="AppLink">
                  <div className="icon orange">
                    <FontAwesomeIcon icon="fa-arrow-up-right-from-square" />
                  </div>
                  <div className="AppTitle">
                    <span className="lead">Anwendung öffnen</span>
                  </div>
                </a>
              </li>
            </ul>
          </Card>
          <Card className="border-top">
            <Typography variant="h4">Ansprechpartner:in</Typography>
            <Typography variant="body2">{appLinkDetails.supportContact}</Typography>
            <Typography variant="body2"><a href={"tel:" + appLinkDetails.supportContactPhone}>{appLinkDetails.supportContactPhone}</a></Typography>
            <Typography variant="body2"><a href={"mailto:" + appLinkDetails.supportContactMail}>{appLinkDetails.supportContactMail}</a></Typography>
          </Card>
        </div>
      </div>
    </Container>
  )
}