import React from 'react';
import { Container } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBracketsRound } from '@fortawesome/pro-duotone-svg-icons';

export default function LoadingScreen() {
  return (
    <Container className="main" maxWidth="lg">
      <FontAwesomeIcon icon={faBracketsRound} className="loadingSpinner" />
    </Container>
  )
}