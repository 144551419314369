import axios from 'axios';
import { GET_APPGROUPS } from './types';

export const getAppGroups = (setLoading) => dispatch => {
    axios.get(process.env.REACT_APP_API + '/portal/appgroups').then(res => {
        dispatch({
            type: GET_APPGROUPS,
            payload: res.data
        });
        setLoading(false)
    }).catch(err => console.log(err));
}