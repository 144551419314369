import React from 'react'
import { Box, Button, Card, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { MobileDateTimePicker, LocalizationProvider, deDE } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { addAlert } from '../../services/actions/alerts';
import { useDispatch } from 'react-redux'

export default function WarningAdd(props) {

  const { setWarningAddOpen } = props;
  const dispatch = useDispatch()

  const [warningAdd, setWarningAdd] = React.useState({
    id: "",
    severity: "",
    message: "",
    start: dayjs().minute(0).second(0),
    end: dayjs().minute(0).second(0),
    button_text: "",
    url: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWarningAdd(warningAdd => ({
      ...warningAdd,
      [name]: value
    }))
  }

  const handleStartDateChange = (newValue) => {
    setWarningAdd(warningAdd => ({
      ...warningAdd,
      start: newValue
    }))
  }

  const handleEndDateChange = (newValue) => {
    setWarningAdd(warningAdd => ({
      ...warningAdd,
      end: newValue
    }))
  }

  const handleSend = (event) => {
    dispatch(addAlert(warningAdd))
    setWarningAddOpen(false)
    event.preventDefault()
  }

  const handleAbort = (event) => {
    setWarningAddOpen(false)
  }

  return (
    <Card>
      
      <Box component="form" onSubmit={handleSend} className="WarningEdit">
        <div className="row">
          <FormControl>
            <RadioGroup name="severity" value={warningAdd.severity} onChange={handleChange}>
              <FormControlLabel value="info" control={<Radio />} label="Info" className="RadioInfo" />
              <FormControlLabel value="warning" control={<Radio />} label="Warnung" className="RadioWarning" />
              <FormControlLabel value="critical" control={<Radio />} label="Kritisch" className="RadioCritical" />
            </RadioGroup>
          </FormControl>
          <div className="WarningTextFields">
            <TextField
              id="message-textfield"
              name="message"
              label="Meldungstext"
              variant="outlined"
              multiline
              minRows={4}
              maxRows={8}
              required
              value={warningAdd.message}
              onChange={handleChange}
            />
            <div className="row url">
              <TextField
                id="url-textfield"
                name="url"
                label="URL (https://beispiel.de)"
                variant="outlined"
                type="url"
                value={warningAdd.url}
                onChange={handleChange}
              />
              <TextField
                id="button-textfield"
                name="button_text"
                label="Button Text"
                variant="outlined"
                required={warningAdd.url ? true : false}
                value={warningAdd.button_text}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="WarningDateTime">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'de'} localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}>
              <MobileDateTimePicker
                label="Von"
                views={['day', 'hours']}
                format={"DD.MM.YYYY HH:mm"}
                value={warningAdd.start}
                onChange={(newValue) => handleStartDateChange(newValue)}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'de'} localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}>
              <MobileDateTimePicker
                label="Bis"
                views={['day', 'hours']}
                format={"DD.MM.YYYY HH:mm"}
                minDateTime={warningAdd.start}
                value={warningAdd.end}
                onChange={(newValue) => handleEndDateChange(newValue)}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="row">
          <div className="WarningButtons">
            <Button variant="contained" className="secondary" disableElevation onClick={handleAbort}>Abbrechen</Button>
            <Button type="submit" variant="contained" disableElevation>Speichern</Button>
          </div>
        </div>
      </Box>
      </Card>
  )
}
