import axios from '../HttpService';
import { GET_ALERTS, GET_ALL_ALERTS } from './types';
import { report_snackbar_error, report_snackbar_success } from './snackbar';

export const getAlerts = () => dispatch => {
    axios.get(process.env.REACT_APP_API + '/portal/alerts').then(res => {
        dispatch({
            type: GET_ALERTS,
            payload: res.data
        });
    }).catch(err => console.log(err));
}

export const getAllAlerts = () => dispatch => {
    axios.get(process.env.REACT_APP_API + '/portal/admin/alerts').then(res => {
        dispatch({
            type: GET_ALL_ALERTS,
            payload: res.data
        });
    }).catch(err => console.log(err))

}

export const editAlert = (alert) => dispatch => {
    axios.patch(process.env.REACT_APP_API + '/portal/admin/alerts/' + alert.id, alert).then(res => {
        dispatch(getAllAlerts())
        dispatch(report_snackbar_success("Änderung wurde übertragen"))
    }).catch(err => {
        console.log(err)
        dispatch(report_snackbar_error("uuups... das hat leider nicht geklappt!"))
    });
}

export const deleteAlert = (alert) => dispatch => {
    axios.delete(process.env.REACT_APP_API + '/portal/admin/alerts/' + alert.id).then(res => {
        dispatch(getAllAlerts())
        dispatch(report_snackbar_success("Der Eintrag wurde gelöscht"))
    }).catch(err => {
        console.log(err)
        dispatch(report_snackbar_error("uuups... das hat leider nicht geklappt!"))
    });
}

export const addAlert = (alert) => dispatch => {
    axios.post(process.env.REACT_APP_API + '/portal/admin/alerts', alert).then(res => {
        dispatch(getAllAlerts())
        dispatch(report_snackbar_success("Der Eintrag wurde erstellt"))
    }).catch(err => {
        console.log(err)
        dispatch(report_snackbar_error("uuups... das hat leider nicht geklappt!"))
    });
}