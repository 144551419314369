import React, { useState } from 'react';
import WarnMessageList from './WarnMessageList';
import GroupList from './GroupList';
import { useDispatch } from 'react-redux';
import { getAppGroups } from '../services/actions/appGroups'
import { getAppLinks } from '../services/actions/appLinks'
import { getNews } from '../services/actions/news'
import { Container } from '@mui/material';
import LoadingScreen from './LoadingScreen';
import { Masonry } from '@mui/lab';

export default function Main() {

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [loadingApps, setLoadingApps] = useState(false)

  React.useEffect(() => {
    dispatch(getAppGroups(setLoading))
    dispatch(getAppLinks(setLoadingApps))
    dispatch(getNews())
  }, [dispatch]);

  if (loading || loadingApps) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <Container className="main">
      <WarnMessageList />
      <Masonry columns={{ xs: 1, md: 2, xl: 3 }} spacing={4}>
        <GroupList />
      </Masonry>
    </Container>
  );
}