import { GET_NEWS } from '../actions/types.js';

const initialState = {
    cr_archive: []
}

export default function News(state = initialState, action) {
    switch (action.type) {
        case GET_NEWS:
            return {
                ...state,
                cr_archive: action.payload
            }
        default:
            return state
    }

}