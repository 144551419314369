import { React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAlerts } from '../services/actions/alerts';
import WarnMessage from './WarnMessage';

export default function WarnMessageList() {

  const dispatch = useDispatch()
  const data = useSelector(state => state.alerts.alerts);

  useEffect(() => {
    dispatch(getAlerts())

  }, [dispatch]);

  return data.map((alert) => (
    <WarnMessage alert={alert} key={alert.id}></WarnMessage>
  ));
}