import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Chip } from '@mui/material';

export default function AppLink(props) {

  const { appLink, color } = props;
  var _paq = window._paq || [];

  const handleAppClick = (e) => {
    if (typeof _paq !== ['undefined']) {
      _paq.push(['trackEvent', 'AppClick', (appLink.friendlyName)]);
    }
  }

  const handleDetailClick = (e) => {
    e.stopPropagation();
    if (typeof _paq !== ['undefined']) {
      _paq.push(['trackEvent', 'AppDetailClick', 'Details']);
    }
  }

  return (
    <li>
      <a href={appLink.link} target="_blank" rel="noreferrer noopener" onClick={handleAppClick} className="AppLink">
        <div className={"icon " + color}>
          <FontAwesomeIcon icon={appLink.icon} />
        </div>
        <div className="AppTitle">
          <div className="AppTitleFriendly">
            <span className="lead">{appLink.friendlyName}</span>
            {appLink.age <= 40 ? <Chip size="small" icon={<FontAwesomeIcon icon="fa-sparkles" />} label="neu" />:""}
          </div>
          <span>{appLink.name}</span>
        </div>
        {/* <div className="AppDetailsLink">
          <object><Link to={"/details/" + appLink.id} onClick={handleDetailClick} title="Details"><FontAwesomeIcon icon="fa-circle-question" /></Link></object>
        </div> */}
      </a>
    </li>
  );
}