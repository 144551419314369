import axios from 'axios';
import { GET_APPLINKS, GET_ALL_APPLINKS, GET_APPLINK_DETAILS } from './types';
import UserService from '../UserService';

export const getAppLinks = (setLoadingApps) => dispatch => {
    axios.get(process.env.REACT_APP_API + '/portal/applinks').then(res => {
        dispatch({
            type: GET_APPLINKS,
            payload: res.data
        });
        setLoadingApps(false)
    }).catch(err => console.log(err));
}

export const getAppLinkDetails = (setLoading, appLinkId) => dispatch => {
    axios.get(process.env.REACT_APP_API + '/portal/applinks/' + appLinkId).then(res => {
        dispatch({
            type: GET_APPLINK_DETAILS,
            payload: res.data
        });
        setLoading(false)
    }).catch(err => console.log(err));
}

export const getAllAppLinks = () => dispatch => {
    UserService.updateToken(
        axios.get(process.env.REACT_APP_API + '/portal/admin/applinks', { headers: { Authorization: `Bearer ` + UserService.getToken()}}).then(res => {
        dispatch({
            type: GET_ALL_APPLINKS,
            payload: res.data
        });
    }).catch(err => console.log(err)));
}