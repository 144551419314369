import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Button, Container } from '@mui/material';
import logo from '../assets/img/Logo_Sozialkontor-Portal.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function Header() {

  let navigate = useNavigate();

  const location = useLocation();

  return (
    <AppBar position="relative">
      <Container>
        <Link to="/" className="navbar-brand"><img className="img-fluid" src={logo} alt="Sozialkontor Logo" width="350" border="0" ></img></Link>
        {location.pathname.includes("details") ? <Button variant="contained" disableElevation className="btn-hellgrau" onClick={() => navigate(-1)} startIcon={<FontAwesomeIcon icon="arrow-left" />}>Zurück</Button> : ""}
        <Button variant="contained" disableElevation className="btn-hellgrau" href="https://www.sozialkontor.de" startIcon={<FontAwesomeIcon icon="home" />} >www.sozialkontor.de</Button>
      </Container>
    </AppBar>
  );
}