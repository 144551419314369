import React from 'react';
import { useSelector } from 'react-redux';

export default function NewsArticle() {

  const cr_archive = useSelector(state => state.news.cr_archive);

  const options = { dateStyle: 'short' };

  var _paq = window._paq || [];

  const handleNewsletterClick = (title) => {
    if (typeof _paq !== ['undefined']) {
      _paq.push(['trackEvent', 'NewsletterClick', title]);
    }
  }

  return cr_archive.map((article) => (
    <div key={article.guid}>
      <a href={article.link} className='crArchiveItem' target="_blank" onClick={() => handleNewsletterClick(article.title)} rel="noreferrer">
        <div className='crArchiveItemDate'>{new Date(article.pubDate).toLocaleDateString('de-DE', options)}</div>
        <div className='crArchiveItemName'>{article.title}</div>
        <div className='crArchiveItemDescription'>{article.description}</div>
      </a>
    </div>
  ));
}