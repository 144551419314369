import { combineReducers } from "redux";
import appGroups from './appGroups';
import appLinks from './appLinks';
import alerts from './alerts';
import news from './news';
import snackbar from './snackbar';

export default combineReducers({
    appGroups,
    appLinks,
    alerts,
    news,
    snackbar,
});