import { CREATE_SNACKBAR, DISCARD_SNACKBAR } from './types';

export const report_snackbar_success = (snackbar_text) => dispatch => {

    dispatch({
        type: CREATE_SNACKBAR,
        payload: {snackbar_text:snackbar_text, snackbar_level:"success"}
    });
}

export const report_snackbar_info = (snackbar_text) => dispatch => {

    dispatch({
        type: CREATE_SNACKBAR,
        payload: {snackbar_text:snackbar_text, snackbar_level:"info"}
    });
}

export const report_snackbar_warning = (snackbar_text) => dispatch => {

    dispatch({
        type: CREATE_SNACKBAR,
        payload: {snackbar_text:snackbar_text, snackbar_level:"warning"}
    });
}

export const report_snackbar_error = (snackbar_text) => dispatch => {

    dispatch({
        type: CREATE_SNACKBAR,
        payload: {snackbar_text:snackbar_text, snackbar_level:"error"}
    });
}

export const deactivateSnackbar = () => dispatch => {
    dispatch({
        type: DISCARD_SNACKBAR,
    });
}