import Keycloak from "keycloak-js";

const _kc = new Keycloak('/keycloak.json');

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
    //responseMode: 'query',
    //promiseType: 'native',
  }).then((authenticated) => {
    if (!authenticated) {
      //console.log("user is not authenticated..!");
    }
    if (authenticated) {
      //console.log("user is  authenticated");
      //console.log(getToken());
    }
    onAuthenticatedCallback()

  })
    .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCall) =>
  _kc.updateToken(3)
    .then(successCall)
    .catch(doLogin);

const checkToken = () => {
  console.log("is Token expired? " + _kc.isTokenExpired() + " duration left: ")
  console.log(new Date(_kc.tokenParsed.exp * 1000))
}

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  checkToken,
};

export default UserService;