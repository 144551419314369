import React from 'react';
import { Card, Container, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminTabView from './AdminTabView'
import ScrollToTop from '../ScrollToTop';

export default function AdminPage() {
  return (
    <Container className="main admin" maxWidth="lg">
      <ScrollToTop />
      <Card className="AdminHeader">
        <div className="icon grau">
          <FontAwesomeIcon icon="fa-screwdriver-wrench" />
        </div>
        <Typography variant="h2">Admin</Typography>
      </Card>
      <AdminTabView />
    </Container>
  )
}