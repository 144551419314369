export const GET_APPGROUPS ='GET_APPGROUPS';
export const GET_APPLINKS ='GET_APPLINKS';
export const GET_MYAPPS ='GET_MYAPPS';
export const GET_ALERTS ='GET_ALERTS';
export const GET_NEWS ='GET_NEWS';
export const GET_ALL_ALERTS ='GET_ALL_ALERTS';
export const GET_ALL_APPLINKS ='GET_ALL_APPLINKS';
export const CREATE_SNACKBAR ='CREATE_SNACKBAR';
export const DISCARD_SNACKBAR ='DISCARD_SNACKBAR';
export const GET_APPLINK_DETAILS ='GET_APPLINK_DETAILS';
