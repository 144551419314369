import { GET_APPLINKS, GET_ALL_APPLINKS, GET_APPLINK_DETAILS } from '../actions/types.js';

const initialState = {
    appLinks: [],
    adminAppLinks: [],
    appLinkDetails: {}
}

export default function AppLinks(state = initialState, action) {
    switch (action.type) {
        case GET_APPLINKS:
            return {
                ...state,
                appLinks: action.payload
            }
        case GET_ALL_APPLINKS:
            return {
                ...state,
                adminAppLinks: action.payload
            }
        case GET_APPLINK_DETAILS:
                return {
                    ...state,
                    appLinkDetails: action.payload
                }
        default:
            return state
    }

}