import React, { useState, useEffect } from 'react';
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export default function Footer() {

    const [curroption, setCurrOption] = useState("")
    const options = [
        'in Hamburg', 
        'im Sozialkontor', 
        'in Hammerbrook',
    ]

    const chooseOption = () => {
        setCurrOption(options[Math.floor(Math.random() * options.length)])
    }

    useEffect(() => {
        chooseOption()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    return (
        <div className="footer">
            <Typography className="madewithlove" onClick={chooseOption}>made with <FontAwesomeIcon icon="fa-heart" className="footerHeart" /> {curroption}</Typography>
            <Typography><span>&copy; {new Date().getFullYear()} BHH Sozialkontor gGmbH</span><span><a href="https://www.sozialkontor.de/impressum/" target="_blank" rel="noreferrer">Impressum</a></span><span><a href="https://www.sozialkontor.de/datenschutz/" target="_blank" rel="noreferrer">Datenschutz</a></span><span><Link to={"/admin"} rel="noreferrer">Admin</Link></span></Typography>
        </div>
    );
}