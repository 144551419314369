import React from 'react'
import { Snackbar, Alert } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { deactivateSnackbar } from '../services/actions/snackbar';

export default function AlertSnackbar() {

    const snackbar = useSelector(state => state.snackbar);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(deactivateSnackbar())
    };

    return (
        <Snackbar open={snackbar.current_snackbar_state} autoHideDuration={10000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={snackbar.snackbar_level} sx={{ width: '100%' }}>
                {snackbar.snackbar_text}
            </Alert>
        </Snackbar>
    )
}