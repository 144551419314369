import * as React from 'react';
import { Tabs, Tab, Card } from '@mui/material';
import TabPanel from './TabPanel';
import AdminPageWarnings from './AdminPageWarnings';
import AdminPageApps from './AdminPageApps';

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function AdminTabView() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Tabs value={value} onChange={handleChange} aria-label="tabs">
        <Tab label="Warnmeldungen" {...a11yProps(0)} />
        <Tab label="Apps" {...a11yProps(1)} />
        <Tab label="Drei" {...a11yProps(2)} />
      </Tabs>
      <Card>
        <TabPanel value={value} index={0}>
          <AdminPageWarnings />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AdminPageApps />
        </TabPanel>
      </Card>
    </React.Fragment>
  );
}