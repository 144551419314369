import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Typography } from '@mui/material';

export default function WarnMessage(props) {

  const { id, message, url, button_text, severity } = props.alert;

  var _paq = window._paq || [];

  const handleWarningClick = (e) => {
    if (typeof _paq !== ['undefined']) {
      _paq.push(['trackEvent', 'WarningClick', (severity)]);
    }
  }

  return (
    <Card className={
      severity === "info" ? "WarnContainer info" :
        severity === "warning" ? "WarnContainer warning" :
          severity === "critical" ? "WarnContainer critical" : ""
    } key={id}>
      {
        severity === "info" ?
          <div className="WarnMessage">
            <div className="icon info">
              <FontAwesomeIcon icon="circle-info" />
            </div>
            <Typography className="WarnContent">
              <span className="WarnLabel text-hellblau">Info:</span> {message}
            </Typography>
          </div>

          : ""
      }
      {
        severity === "warning" ?
          <div className="WarnMessage">
            <div className="icon warning">
              <FontAwesomeIcon icon="exclamation-triangle" />
            </div>
            <Typography className="WarnContent">
              <span className="WarnLabel">Warnung:</span> {message}
            </Typography>
          </div>

          : ""
      }
      {
        severity === "critical" ?
          <div className="WarnMessage">
            <div className="icon critical">
              <FontAwesomeIcon icon="hexagon-exclamation" />
            </div>
            <Typography className="WarnContent">
              <span className="WarnLabel">Kritisch:</span> {message}
            </Typography>
          </div>

          : ""
      }

      {!!url ? <Button variant="contained" disableElevation className="btn-hellgrau" href={url} onClick={handleWarningClick}>{button_text}</Button> : ""}
    </Card>
  );
}