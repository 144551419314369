import { Card } from '@mui/material'
import React from 'react'

export default function AdminPageApps() {
  return (
    <div className="tab-grid">
      <Card>Apps</Card>
      <Card>More</Card>
    </div>
  )
}
