import { GET_ALERTS, GET_ALL_ALERTS } from '../actions/types.js';

const initialState = {
    alerts: [],
    adminAlerts: []
}

export default function Alerts(state = initialState, action) {
    switch (action.type) {
        case GET_ALERTS:
            return {
                ...state,
                alerts: action.payload
            }
        case GET_ALL_ALERTS:
            return {
                ...state,
                adminAlerts: action.payload
            }
        default:
            return state
    }

}