import React, { useState } from 'react'
import { Card, Typography } from '@mui/material';
import WarningEdit from './WarningEdit';

export default function Warning(props) {

  const { warning } = props

  const [show, setShow] = useState(false);

  const editWarning = () => {
    setShow(!show);
  }

  return (
    <div className="WarningAdmin">
      <Card className={warning.severity} onClick={editWarning}>
        {warning.severity === 'info' ? <Typography className="severity">Info</Typography> : ""}
        {warning.severity === 'warning' ? <Typography className="severity">Warnung</Typography> : ""}
        {warning.severity === 'critical' ? <Typography className="severity">Kritisch</Typography> : ""}
        <Typography>{warning.message}</Typography>
      </Card>
      {show ? <WarningEdit warning={warning} setShow={setShow} /> : ""}
    </div>
  )
}
