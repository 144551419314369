import React from 'react';
import AppLinkList from './AppLinkList';
import { useSelector } from 'react-redux';
import { Card, Typography } from '@mui/material';

export default function Group(props) {

  const { group } = props
  const appLinks = useSelector(state => state.appLinks.appLinks.filter(item => item.appGroup === group.id))

  if (appLinks.length > 0) {
    return (
      <Card className={"app-list border-top-" + group.groupColor}>
        <Typography variant="h2" className={"text-" + group.groupColor}>{group.groupName}</Typography>
        <nav>
          <ul className="list-unstyled">
            <AppLinkList appLinks={appLinks} color={group.groupColor} />
          </ul>
        </nav>
      </Card>
    )
  } else {
    return (

      <Card className={"app-list border-top-" + group.groupColor}>
        <Typography variant="h2" className={"text-" + group.groupColor}>{group.groupName}</Typography>
        <nav>
          <ul className="list-unstyled">
            <span className="lead">Du bist nicht mit dem Firmennetzwerk verbunden. Daher tauchen an dieser Stelle keine Apps auf.</span>
          </ul>
        </nav>
      </Card>
    )

  }

}