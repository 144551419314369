import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAlerts } from '../../services/actions/alerts';
import Warning from './Warning';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WarningAdd from './WarningAdd';

export default function AdminPageWarnings() {

  const dispatch = useDispatch();
  const adminAlerts = useSelector(state => state.alerts.adminAlerts)

  const [warningAddOpen, setWarningAddOpen] = useState(false);

  const handleOpen = () => {
    setWarningAddOpen(true)
  }

  React.useEffect(() => {
    dispatch(getAllAlerts())
  }, [dispatch]);

  React.useEffect(() => {
  }, [adminAlerts]);

  return (
    <div className="tab-flex">
      <Button variant="contained" disableElevation onClick={handleOpen} className="btn-hellgrau ButtonAddWarning" startIcon={<FontAwesomeIcon icon="fa-pen-to-square" />}>Neue Warnmeldung hinzufügen</Button>
      {warningAddOpen ? <WarningAdd setWarningAddOpen={setWarningAddOpen} /> : ""}
      {adminAlerts.map((alert) => <Warning warning={alert} key={alert.id}/>)}
    </div>
  );
}