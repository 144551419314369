import React from 'react';
import { Card, Typography } from '@mui/material';
import Logo from '../assets/img/Hausgrafik_SK.svg';
import NewsArticle from './NewsArticle';

export default function Newsletter() {

  return (
    <Card className="app-list border-top-petrol">
      <div className="news-header">
        <img src={Logo} alt="Sozialkontor Logo" className="news-logo" />
        <div className="news-title">
          <Typography variant="h2">Newsletter</Typography>
          <Typography>für die Beschäftigten des Sozialkontors</Typography>
        </div>
      </div>
      <div>
        <div id='crArchive' className='crArchive'>
          <NewsArticle />
        </div>
      </div>
    </Card>
  );
}