import './css/app.css';

import Header from './components/Header';
import Main from './components/Main';
import AppLinkDetails from './components/AppLinkDetails';
import AdminPage from './components/admin_page/AdminPage';
import RequireAuth from './components/RequireAuth';
import Footer from './components/Footer';
import AlertSnackbar from './components/AlertSnackbar';

import { Provider } from 'react-redux';
import store from './services/StoreService';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';

import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/pro-solid-svg-icons';

const iconList = Object.keys(Icons)
  .filter((key) => key !== 'fas' && key !== 'prefix')
  .map((icon) => Icons[icon]);

library.add(...iconList);

export default function App() {
  return (
    <Provider store={store}>
      <Router>
        <StyledEngineProvider injectFirst>
          <Header />
          <Routes>
            <Route path='/' element={<Main />} />
            <Route path='/details/:id' element={<AppLinkDetails />} />
            <Route path='/admin' element={<RequireAuth><AdminPage /></RequireAuth>} />
          </Routes>
          <Footer />
          <AlertSnackbar />
        </StyledEngineProvider>
      </Router>
    </Provider>
  );
}
